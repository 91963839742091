var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.activeInactiveTypes(),
                        },
                        model: {
                          value: _vm.filters.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "active", $$v)
                          },
                          expression: "filters.active",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          type: "vue-select",
                          label: _vm.$t("Atm"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.yesNoTypes(),
                        },
                        model: {
                          value: _vm.filters.isAtm,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "isAtm", $$v)
                          },
                          expression: "filters.isAtm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.canDownloadDesktopApp
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("e-button", {
                            attrs: {
                              variant: "primary",
                              text: _vm.$t("Download desktop app"),
                            },
                            on: { click: _vm.desktopAppDownload },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          staticClass: "mb-2",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(_vm.$tc("PAYBOX.NAME")),
              fields: _vm.fields,
              items: _vm.payBoxes,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(remoteConnection)",
                fn: function (row) {
                  return [
                    row.item.anyDeskId
                      ? _c("div", [
                          _c("b", [_vm._v(_vm._s(_vm.$t("AnyDesk")) + ":")]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "hyperlink",
                              attrs: { href: "anydesk:" + row.item.anyDeskId },
                            },
                            [_vm._v(_vm._s(row.item.anyDeskId))]
                          ),
                          _vm._v(" "),
                          row.item.anyDeskPassword
                            ? _c("span", [
                                _vm._v(
                                  "(" + _vm._s(row.item.anyDeskPassword) + ")"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    row.item.teamViewerId
                      ? _c("div", [
                          _c("b", [_vm._v(_vm._s(_vm.$t("TeamViewer")) + ":")]),
                          _vm._v(" " + _vm._s(row.item.teamViewerId) + " "),
                          row.item.teamViewerPassword
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.item.teamViewerPassword) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-update": _vm.$can("Update", "PayBox"),
                        "show-delete": false,
                        "show-activate": _vm.$can("Activate", "PayBox"),
                        "show-deactivate": _vm.$can("Deactivate", "PayBox"),
                        buttons: _vm.buttons,
                      },
                      on: {
                        update: function ($event) {
                          return _vm.onShowSidebar(row.item)
                        },
                        activate: function ($event) {
                          return _vm.activateDeactivatePayBox(row)
                        },
                        deactivate: function ($event) {
                          return _vm.activateDeactivatePayBox(row)
                        },
                        "request-logs": function ($event) {
                          return _vm.requestLogsPayBox(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "table-manage-pay-box",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("manage-pay-box-sidebar", {
        ref: "managePayBoxSidebar",
        on: { "after-save": _vm.getData },
      }),
      _vm.$can("Create", "PayBox")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar novo Caixa") },
            on: { click: _vm.onShowSidebar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }