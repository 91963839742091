<template>
  <div>
    <e-sidebar
      :title="isEdit ? $t('Editar Caixa') : $t('Novo Caixa')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="savePayBox"
      @hidden="showSidebar = false"
    >
      <template #content>
        <FormulateForm
          ref="payBoxForm"
          name="payBoxForm"
        >
          <b-row>
            <b-col md="12">
              <e-store-combo
                id="manage_pay_box_sidebar-store_id"
                v-model="localPayBox.storeId"
              />
            </b-col>

            <b-col md="5">
              <FormulateInput
                id="manage_pay_box_sidebar-number"
                v-model="localPayBox.number"
                type="number"
                class="required"
                :label="$t('Número do caixa')"
                validation="required|number"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="manage_pay_box_sidebar-description"
                v-model="localPayBox.description"
                type="textarea"
                :label="$t('Descrição')"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="manage_pay_box_sidebar-event-id"
                v-model="localPayBox.eventId"
                type="vue-select"
                :label="$t('Evento')"
                :options="getEventOptions"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                v-model="localPayBox.isAtm"
                type="switch"
                :label="$t('ATM')"
                :instruction="$t('Indica se o caixa é de auto atendimento')"
              />
            </b-col>

            <b-col md="6">
              <FormulateInput
                id="manage_pay_box_sidebar-anydesk_id"
                v-model="localPayBox.anyDeskId"
                type="number"
                :label="$t('Id AnyDesk')"
              />
            </b-col>
            <b-col md="6">
              <FormulateInput
                id="manage_pay_box_sidebar-anydesk_password"
                v-model="localPayBox.anyDeskPassword"
                type="text"
                :label="$t('Senha AnyDesk')"
              />
            </b-col>

            <b-col md="6">
              <FormulateInput
                id="manage_pay_box_sidebar-teamviewer_id"
                v-model="localPayBox.teamViewerId"
                type="number"
                :label="$t('Id TeamViewer')"
              />
            </b-col>
            <b-col md="6">
              <FormulateInput
                id="manage_pay_box_sidebar-teamviewer_password"
                v-model="localPayBox.teamViewerPassword"
                type="text"
                :label="$t('Senha TeamViewer')"
              />
            </b-col>
            <b-col
              v-if="isEdit"
              md="12"
            >
              <FormulateInput
                id="pay_box_sat_tab-preferred_invoice_type"
                v-model="localPayBox.preferredInvoiceType"
                type="vue-select"
                :label="$t('Documento fiscal padrão')"
                :placeholder="$t('Selecione o documento fiscal padrão')"
                :options="localPayBox.availableInvoiceTypes"
                class="required"
                validation="required"
              />
            </b-col>
            <b-col
              v-if="isEdit"
              md="12"
            >
              <FormulateInput
                id="manage_pay_box_sidebar-nfce-last_invoice_number"
                v-model="localPayBox.nfce.lastInvoiceNumber"
                type="number"
                :label="$t('Código da última NFC-e emitida')"
                class="required"
                validation="required"
              />
            </b-col>
          </b-row>
        </FormulateForm>
        <b-card-actions
          v-if="localPayBox.finishedSetup"
          no-actions
          :title="$t('Outras informações')"
        >
          <b-col md="12">
            <strong>{{ $t('Endereço MAC') }}:</strong>
            <br>
            <span>{{ localPayBox.macAddress }}</span>
          </b-col>

          <b-col md="12">
            <strong>{{ $t('Configuração Completa') }}:</strong>
            <pre>{{ localPayBox }}</pre>
          </b-col>
        </b-card-actions>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { uploader } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

const getInitialPayBox = () => ({
  id: null,
  storeId: null,
  number: '',
  description: '',
  eventId: null,
  isAtm: false,
  token: '',
  macAddress: '',
  finishedSetup: false,
  anyDeskId: null,
  anyDeskPassword: null,
  teamViewerId: null,
  teamViewerPassword: null,
  preferredInvoiceType: null,
})

export default {
  components: {
    ESidebar,
    BCardActions,
    BRow,
    BCol,
    EStoreCombo,
  },

  mixins: [uploader],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      localPayBox: getInitialPayBox(),
    }
  },

  computed: {
    ...mapGetters('pages/pdv/managePayBox', ['eventOptions']),
    getEventOptions() {
      if (this.localPayBox?.event?.id) {
        return [
          { label: this.localPayBox.event.name, value: this.localPayBox.event.id },
          ...this.eventOptions
        ]
      }

      return this.eventOptions
    },
    isEdit() {
      return !!this.localPayBox?.id
    },
  },

  methods: {
    ...mapActions('pages/pdv/managePayBox', ['fetchPayBoxById', 'fetchEvents']),

    showCreate() {
      this.localPayBox = getInitialPayBox()
      this.showSidebar = true
    },

    async show(item) {
      this.fetchEvents()
      if (!item?.id) {
        this.showCreate()
      } else {
        try {
          this.fetching = true
          this.showSidebar = true

          this.localPayBox = await this.fetchPayBoxById(item.id)
        } catch (error) {
          this.showError({ error })
          this.showSidebar = false
        } finally {
          this.fetching = false
        }
      }
    },

    async savePayBox() {
      this.$refs.payBoxForm.showErrors()
      if (this.$refs.payBoxForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true

        await this.$http({
          url: '/api/sales/pay-box',
          method: this.isEdit ? 'PUT' : 'POST',
          data: this.localPayBox,
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.showSidebar = false
        this.$emit('after-save')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
