<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              v-model="filters.isAtm"
              type="vue-select"
              :label="$t('Atm')"
              :placeholder="$t('Todos')"
              :options="yesNoTypes()"
            />
          </b-col>
        </b-row>
        <b-row v-if="canDownloadDesktopApp">
          <b-col>
            <e-button
              variant="primary"
              :text="$t('Download desktop app')"
              @click="desktopAppDownload"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      class="mb-2"
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PAYBOX.NAME'))"
        :fields="fields"
        :items="payBoxes"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(remoteConnection)="row">
          <div v-if="row.item.anyDeskId">
            <b>{{ $t('AnyDesk') }}:</b>&nbsp;
            <a
              class="hyperlink"
              :href="`anydesk:${row.item.anyDeskId}`"
            >{{
              row.item.anyDeskId
            }}</a>
            &nbsp;<span v-if="row.item.anyDeskPassword">({{ row.item.anyDeskPassword }})</span>
          </div>
          <div v-if="row.item.teamViewerId">
            <b>{{ $t('TeamViewer') }}:</b>&nbsp; {{ row.item.teamViewerId }}
            <span v-if="row.item.teamViewerPassword">({{ row.item.teamViewerPassword }})</span>
          </div>
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'PayBox')"
            :show-delete="false"
            :show-activate="$can('Activate', 'PayBox')"
            :show-deactivate="$can('Deactivate', 'PayBox')"
            :buttons="buttons"
            @update="onShowSidebar(row.item)"
            @activate="activateDeactivatePayBox(row)"
            @deactivate="activateDeactivatePayBox(row)"
            @request-logs="requestLogsPayBox(row)"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="table-manage-pay-box"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <manage-pay-box-sidebar
      ref="managePayBoxSidebar"
      @after-save="getData"
    />

    <fab
      v-if="$can('Create', 'PayBox')"
      :main-tooltip="$t('Adicionar novo Caixa')"
      @click="onShowSidebar"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes, translate } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EButton from '@/views/components/EButton.vue'
import isElectron from 'is-electron'
import ManagePayBoxSidebar from './ManagePayBoxSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
    EStoreCombo,
    ManagePayBoxSidebar,
    EButton,
  },

  mixins: [statusTypes, translate],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/managePayBox', {
      payBoxes: 'payBoxes',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    canDownloadDesktopApp() {
      return this.$can('Setup', 'PayBox') && !isElectron()
    },

    buttons() {
      return [
        {
          icon: 'journal-code',
          event: 'request-logs',
          title: this.$t('Solicitar logs'),
        },
      ]
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Código'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
        },
        {
          label: this.$t('Número'),
          key: 'number',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Conexão Remota'),
          key: 'remoteConnection',
          tdClass: 'text-left',
          thStyle: { width: '250px' },
        },
        {
          label: this.$t('Endereço MAC'),
          key: 'macAddress',
          tdClass: 'text-left',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Status'),
          key: 'active',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/managePayBox', [
      'fetchPayBoxes',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    ...mapActions('pages/pdv/payBoxConfiguration', ['desktopAppDownload']),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchPayBoxes()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onShowSidebar(item) {
      this.$refs.managePayBoxSidebar.show(item)
    },

    async requestLogsPayBox(row) {
      try {
        await this.$http({
          url: `/api/support/workstation/${row.item.id}/logs`,
          method: 'POST',
        })
        this.showSuccess({
          message: this.$t('Logs requisitados'),
        })
      } catch (error) {
        this.showError({ error })
      }
    },
    async activateDeactivatePayBox(row) {
      try {
        const confirm = await this.confirm({
          text: this.$t(
            `Tem certeza que deseja ${row.item.active ? 'Inativar' : 'Ativar'} este caixa?`
          ),
        })
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/sales/pay-box/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
